import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";

import appReducer from "./appReducer";
import pagePromptsReducer from "./pagePromptsReducer";
import galleryReducer from "./galleryReducer";
import dataReducer from "./dataReducer";
import selfieReducer from "./selfieReducer";

export type StateType = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  appReducer,
  pagePromptsReducer,
  galleryReducer,
  dataReducer,
  selfieReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
export default store;
