import Types from 'src/Constants/Types';

const initialState: AppReducerType = {
  loading: true,
  currentStep: 'guideline',
  isSidebarOpen: false,
  showModalMenu: null,
  modalWarning: false,
  errorScreen: null,
  backFunction: '',
};

type LoadingAction = { type: typeof Types.SET_LOADING; value: boolean };
type CurrentStepAction = { type: typeof Types.SET_CURRENT_STEP; value: string };
type SidebarAction = { type: typeof Types.SET_IS_SIDEBAR_OPEN; value: boolean };
type ShowModalMenuAction = {
  type: typeof Types.SET_SHOW_MODAL_MENU;
  value: null | string;
};
type ModalWarningAction = {
  type: typeof Types.SET_MODAL_WARNING;
  value: boolean;
};
type ErrorScreenAction = {
  type: typeof Types.SET_ERROR_SCREEN;
  value: ErrorScreenType | null;
};

type BackFunction = {
  type: typeof Types.SET_BACK_FUNCTION;
  value: string;
};

type AppReducerAction =
  | LoadingAction
  | CurrentStepAction
  | SidebarAction
  | ShowModalMenuAction
  | ModalWarningAction
  | ErrorScreenAction
  | BackFunction;

export default function appReducer(
  state = initialState,
  action: AppReducerAction
) {
  switch (action.type) {
    case Types.SET_LOADING: {
      return { ...state, loading: action.value };
    }
    case Types.SET_CURRENT_STEP: {
      return { ...state, currentStep: action.value };
    }
    case Types.SET_IS_SIDEBAR_OPEN: {
      return { ...state, isSidebarOpen: action.value };
    }
    case Types.SET_SHOW_MODAL_MENU: {
      return { ...state, showModalMenu: action.value };
    }
    case Types.SET_MODAL_WARNING: {
      return { ...state, modalWarning: action.value };
    }
    case Types.SET_ERROR_SCREEN: {
      return { ...state, errorScreen: action.value };
    }
    case Types.SET_BACK_FUNCTION: {
      return { ...state, backFunction: action.value };
    }
    default:
      return state;
  }
}
