import Lottie from 'react-lottie-player';
import loadingJson from './loading.json';

const Loading = () => {
  return (
    <>
      <Lottie
        loop
        animationData={loadingJson}
        play
        style={{ width: 100, height: 100, marginBottom: '100px' }}
      />
    </>
  );
};

export default Loading;
