import Types from 'src/Constants/Types';
import stylesJSON from 'src/styles.json';

const initialState: PagePromptsReducerType = {
  promptsLocation: '',
  styleSelected: stylesJSON.data[0],
  imageGenerated: '',
  promptGenerated: '',
  dataImageGenerated: null,
};

type PromptsLocationAction = {
  type: typeof Types.SET_PROMPTS_LOCATION;
  value: string;
};
type StyleSelectedAction = {
  type: typeof Types.SET_STYLE_SELECTED;
  value: string | null;
};
type ImageGeneratedAction = {
  type: typeof Types.SET_IMAGE_GENERATED;
  value: string;
};
type PromptGeneratedAction = {
  type: typeof Types.SET_PROMPT_GENERATED;
  value: string;
};
type ResetExperienceAction = {
  type: typeof Types.RESET_EXPERIENCE;
  value: undefined;
};

type dataImageGeneratedAction = {
  type: typeof Types.SET_DATA_IMAGE_GENERATED;
  value: {
    promptId: string;
    promptSortKey: string;
  };
};

type PagePromptsReducerAction =
  | PromptsLocationAction
  | StyleSelectedAction
  | ImageGeneratedAction
  | PromptGeneratedAction
  | ResetExperienceAction
  | dataImageGeneratedAction;

export default function pagePromptsReducer(
  state = initialState,
  action: PagePromptsReducerAction
) {
  switch (action.type) {
    case Types.SET_PROMPTS_LOCATION: {
      return { ...state, promptsLocation: action.value };
    }
    case Types.SET_STYLE_SELECTED: {
      return { ...state, styleSelected: action.value };
    }
    case Types.SET_IMAGE_GENERATED: {
      return { ...state, imageGenerated: action.value };
    }
    case Types.SET_PROMPT_GENERATED: {
      return { ...state, promptGenerated: action.value };
    }

    case Types.RESET_EXPERIENCE: {
      return {
        promptsLocation: '',
        styleSelected: null,
        imageGenerated: '',
        promptGenerated: '',
      };
    }
    case Types.SET_DATA_IMAGE_GENERATED: {
      return { ...state, dataImageGenerated: action.value };
    }
    default:
      return state;
  }
}
