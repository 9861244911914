import Loading from 'src/Components/Common/Loading';

interface LoadingProps {
  show: boolean;
}

const Fallback = (props: LoadingProps) => {
  const { show } = props;
  return (
    <>
      {show && (
        <div style={styles.container}>
          <Loading />
        </div>
      )}
    </>
  );
};

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default Fallback;
