const TYPES = {
  //App
  SET_LOADING: 'SET_LOADING',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_IS_SIDEBAR_OPEN: 'SET_IS_SIDEBAR_OPEN',
  SET_SHOW_MODAL_MENU: 'SET_SHOW_MODAL_MENU',
  SET_MODAL_WARNING: 'SET_MODAL_WARNING',
  SET_ERROR_SCREEN: 'SET_ERROR_SCREEN',
  SET_BACK_FUNCTION: 'SET_BACK_FUNCTION',

  //Prompts
  RESET_EXPERIENCE: 'RESET_EXPERIENCE',
  SET_PROMPTS_LOCATION: 'SET_PROMPTS_LOCATION',
  SET_STYLE_SELECTED: 'SET_STYLE_SELECTED',
  SET_IMAGE_GENERATED: 'SET_IMAGE_GENERATED',
  SET_PROMPT_GENERATED: 'SET_PROMPT_GENERATED',
  SET_DATA_IMAGE_GENERATED: 'SET_DATA_IMAGE_GENERATED',

  //Gallery
  SET_PAGE: 'SET_PAGE',
  SET_LOADING_GALLERY: 'SET_LOADING_GALLERY',
  SET_GALLERY_IMAGES: 'SET_GALLERY_IMAGES',
  MERGE_GALLERY_IMAGES: 'MERGE_GALLERY_IMAGES',
  SET_DISABLE_NEXT: 'SET_DISABLE_NEXT',
  SET_IMAGE_MODAL: 'SET_IMAGE_MODAL',
  ADD_LOADING_LIST: 'ADD_LOADING_LIST',
  ADD_DELETED_LIST: 'ADD_DELETED_LIST',

  //Data
  SET_IMAGES_NUMBER: 'SET_IMAGES_NUMBER',
  SET_IMAGES_SLIDER: 'SET_IMAGES_SLIDER',

  //Selfie
  SET_SELFIE_IMAGE_BASE64: 'SET_SELFIE_IMAGE_BASE64',
  SET_IMAGE_BASE64: 'SET_IMAGE_BASE64',
  SET_SELFIE_KEY: 'SET_SELFIE_KEY',
};

export default TYPES;
