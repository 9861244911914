import Types from 'src/Constants/Types';

const initialState: GalleryReducerType = {
  page: 1,
  galleryImages: undefined,
  loading: true,
  disableNext: false,
  imageModal: null,
  loadingList: [],
  deletedList: [],
};

type PageAction = { type: typeof Types.SET_PAGE; value: number };
type LoadingAction = { type: typeof Types.SET_LOADING_GALLERY; value: boolean };
type SetGalleryAction = {
  type: typeof Types.SET_GALLERY_IMAGES;
  value: GalleImageType[];
};
type MergeGalleryAction = {
  type: typeof Types.MERGE_GALLERY_IMAGES;
  value: GalleImageType[];
};
type DisableNextAction = {
  type: typeof Types.SET_DISABLE_NEXT;
  value: boolean;
};
type SetImageModalAction = {
  type: typeof Types.SET_IMAGE_MODAL;
  value: ImageModalType | null;
};
type AddLoadingListAction = {
  type: typeof Types.ADD_LOADING_LIST;
  value: string;
};
type AddDeletedListAction = {
  type: typeof Types.ADD_DELETED_LIST;
  value: string;
};

type GalleryReducerAction =
  | PageAction
  | LoadingAction
  | SetGalleryAction
  | MergeGalleryAction
  | DisableNextAction
  | SetImageModalAction
  | AddLoadingListAction
  | AddDeletedListAction;

export default function galleryReducer(
  state = initialState,
  action: GalleryReducerAction
) {
  switch (action.type) {
    case Types.SET_PAGE: {
      return { ...state, page: action.value };
    }
    case Types.SET_LOADING_GALLERY: {
      return { ...state, loading: action.value };
    }
    case Types.SET_GALLERY_IMAGES: {
      return { ...state, galleryImages: action.value };
    }
    case Types.MERGE_GALLERY_IMAGES: {
      return {
        ...state,
        galleryImages: [
          ...(state.galleryImages as GalleImageType[]),
          ...(action.value as GalleImageType[]),
        ],
      };
    }
    case Types.SET_DISABLE_NEXT: {
      return { ...state, disableNext: action.value };
    }
    case Types.SET_IMAGE_MODAL: {
      return { ...state, imageModal: action.value };
    }
    case Types.ADD_LOADING_LIST: {
      return {
        ...state,
        loadingList: [...state.loadingList, action.value],
      };
    }
    case Types.ADD_DELETED_LIST: {
      return {
        ...state,
        deletedList: [...state.deletedList, action.value],
      };
    }
    default:
      return state;
  }
}
