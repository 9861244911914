import Types from 'src/Constants/Types';

const initialState: SelfieReducerType = {
  selfieImageBase64: '',
  imageBase64: '',
  selfieKey: '',
};

type SelfieImageBase64Action = {
  type: typeof Types.SET_SELFIE_IMAGE_BASE64;
  value: string;
};
type ImageBase64Action = { type: typeof Types.SET_IMAGE_BASE64; value: string };
type SelfieKeyAction = { type: typeof Types.SET_SELFIE_KEY; value: string };

type SelfieReducerAction =
  | SelfieImageBase64Action
  | ImageBase64Action
  | SelfieKeyAction;

export default function selfieReducer(
  state = initialState,
  action: SelfieReducerAction
) {
  switch (action.type) {
    case Types.SET_SELFIE_IMAGE_BASE64: {
      return { ...state, selfieImageBase64: action.value };
    }
    case Types.SET_IMAGE_BASE64: {
      return { ...state, imageBase64: action.value };
    }
    case Types.SET_SELFIE_KEY: {
      return { ...state, selfieKey: action.value };
    }
    default:
      return state;
  }
}
