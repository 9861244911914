import Types from 'src/Constants/Types';

const initialState: DataReducerType = {
  imagesNumber: undefined,
  imagesSlider: undefined,
};

type ImagesNumberAction = {
  type: typeof Types.SET_IMAGES_NUMBER;
  value: number;
};
type ImagesSliderAction = {
  type: typeof Types.SET_IMAGES_SLIDER;
  value: ImageSliderType[];
};

type DataReducerAction = ImagesNumberAction | ImagesSliderAction;

export default function dataReducer(
  state = initialState,
  action: DataReducerAction
) {
  switch (action.type) {
    case Types.SET_IMAGES_NUMBER: {
      return { ...state, imagesNumber: action.value };
    }
    case Types.SET_IMAGES_SLIDER: {
      return { ...state, imagesSlider: action.value };
    }
    default:
      return state;
  }
}
